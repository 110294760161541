import React, { useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import ContactForm from "./ContactForm";
import Svg from "./Svg";
import { ModalContext } from "./ModalContext";

export default function Modal() {
  const { open, setOpen } = useContext(ModalContext);

  useEffect(() => {
    const [body] = document.getElementsByTagName("body");
    body.style.overflow = "hidden";

    return () => body.removeAttribute("style");
  }, [open]);

  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <div className="modal-overlay" onClick={() => setOpen(false)} />
      <div className="td-modal container pb-3">
        <div className="modal_close mt-4 mr-2" onClick={() => setOpen(false)}>
          <Svg name="close" height={'20px'} width={'20px'} />
        </div>
        <ContactForm inModal={true} setOpen={setOpen} />
      </div>
    </>,
    document.getElementById("modal")
  )
}