import React, { useContext } from 'react';
import { ModalContext } from './ModalContext';
import List from './List';

export default function Bar({ tier, padding, hover, list }) {
  const { setOpen } = useContext(ModalContext);

  return (
    <div className={`tier-${tier}`} onClick={() => { hover.hover == tier ? setOpen(true) : setOpen(false) }}>
      <h4>Tier {tier}</h4>
      <h1 className={`${hover.hover === tier ? "hover" : null}`}><List list={list} /></h1>
    </div>
  )
}
