import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ModalContext } from "./ModalContext";

export default function TriggerModal() {
  const { open, setOpen } = useContext(ModalContext);


  const navidate = useNavigate();

  useEffect(() => {
    setOpen(true);

    return () => navidate("/");

  }, [])

  return null;
}