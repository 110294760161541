import React, { useState } from 'react';
import Bar from './Bar';

export default function Graph({ data }) {
  const [hover, setHover] = useState(false);
  return (
    <div className="container graph">
      <div className='bars row'>
        {data.map((data, index) => {
          return (
            <div key={index} className='bar col-2' onMouseEnter={() => setHover({ hover: index + 1 })} onMouseLeave={() => setHover(false)}>
              <Bar tier={index + 1} list={data.list} price={data.price} padding={data.padding} text={data.text} hover={hover} />
            </div>
          );
        })}
      </div>
      <div className='prices row'>
        {data.map((data, index) => {
          return (
            <div key={index} className='col-2' onMouseEnter={() => setHover({ hover: index + 1 })} onMouseLeave={() => setHover(false)}>
              <div className={`price ${hover.hover === index + 1 ? "hover" : null}`}>${data.price}</div>
            </div>
          );
        })}
      </div>
    </div>
  )
}
