import React from 'react';
import Svg from './Svg';
import Video from './Video';
import videos from "../utils/videos.json"

export default function ServiceBlocks({ highlights }) {
  return (
    <div className='service-blocks container'>
      <div className='row'>
        {
          !highlights ?
            <div className='row blocks'>
              <div className='block col-lg-4 col-5 mt-lg-4 mt-md-5 mt-sm-3'>
                <Svg name="desktop" width="40%" height="120px" margin="2rem auto 0" />
                <h5 className='p-lg-3 my-lg-4 p-md-4 p-sm-3'>Website Contruction</h5>
              </div>
              <div className='block col-lg-4 col-5 mt-lg-4 mt-md-5 mt-sm-3'>
                <Svg name="wrench" width="40%" height="120px" margin="2rem auto 0" />
                <h5 className='p-lg-3 my-lg-4 p-md-4 p-sm-3'>Maintenance</h5>
              </div>
              <div className='block col-lg-4 col-5 mt-lg-4 mt-md-5 mt-sm-3'>
                <Svg name="checklist" width="40%" height="120px" margin="2rem auto 0" />
                <h5 className='p-lg-3 my-lg-4 p-md-4 p-sm-3'>Project Management</h5>
              </div>
              <div className='block col-lg-4 col-5 mt-lg-4 mt-md-5 mt-sm-3'>
                <Svg name="bulb" width="40%" height="120px" margin="2rem auto 0" />
                <h5 className='p-lg-3 my-lg-4 p-md-4 p-sm-3'>Delivery Solutions Architect</h5>
              </div>
              <div className='block col-lg-4 col-5 mt-lg-4 mt-md-5 mt-sm-3'>
                <Svg name="notes" width="40%" height="120px" margin="2rem auto 0" />
                <h5 className='p-lg-3 my-lg-4 p-md-4 p-sm-3'>Internal Tool(s) Curation</h5>
              </div>
              <div className='block col-lg-4 col-5 mt-lg-4 mt-md-5 mt-sm-3'>
                <Svg name="drupal" width="40%" height="120px" margin="2rem auto 0" />
                <h5 className='p-lg-3 my-lg-4 p-md-4 p-sm-3'>Drupal-Related Services</h5>
              </div>
            </div>
            :
            <div className='row'>
              <div className='col-4 service-blocks-video'>
                <div className='video'>
                  <iframe title="Tandev Highlights Reel" src={videos.highlights} width="640px" height="708" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
              <div className='blocks col-7 row'>
                <div className='col blocks-col'>
                  <div className='block'>
                    <Svg name="desktop" width="40%" height="120px" margin="1.5rem auto 0" />
                    <h5 className='p-lg-2 my-lg-4 p-md-4 p-sm-3'>Website Contruction</h5>
                  </div>
                  <div className='block'>
                    <Svg name="wrench" width="40%" height="120px" margin="1.5rem auto 0" />
                    <h5 className='p-lg-2 my-lg-4 p-md-4 p-sm-3'>Maintenance</h5>
                  </div>
                  <div className='block'>
                    <Svg name="checklist" width="40%" height="120px" margin="1.5rem auto 0" />
                    <h5 className='p-lg-2 my-lg-4 p-md-4 p-sm-3'>Project Management</h5>
                  </div>
                </div>
                <div className='col blocks-col'>
                  <div className='block'>
                    <Svg name="bulb" width="40%" height="120px" margin="1.5rem auto 0rem" />
                    <h5 className='bottom-block p-lg-2 my-lg-4 p-md-3 p-sm-3'>Delivery Solutions Architect</h5>
                  </div>
                  <div className='block'>
                    <Svg name="notes" width="40%" height="120px" margin="1.5rem auto 0rem" />
                    <h5 className='bottom-block p-lg-2 my-lg-4 p-md-3 p-sm-3'>Internal Tool(s) Curation</h5>
                  </div>
                  <div className='block'>
                    <Svg name="drupal" width="40%" height="120px" margin="1.5rem auto 0rem" />
                    <h5 className='bottom-block p-lg-2 my-lg-4 p-md-3 p-sm-3'>Drupal-Related Services</h5>
                  </div>
                </div>
              </div>
            </div>
        }
      </div >
    </div>
  )
}
