import React from 'react';
import '@slightlyoff/lite-vimeo';

export default function Video({ id, width, height = 'auto' }) {
  return (
    <div className="video" style={{ width: width, maxHeight: height }}>
      <lite-vimeo videoid={id} autoload autoplay></lite-vimeo>
    </div>
  )
}
