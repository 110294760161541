import React from 'react';
import { Link } from 'react-router-dom';
import Svg from './Svg';

export default function PageNotFound() {
    return (
        <div className='page-not-found'>
            <Link className='text' to='/'><Svg name="tandev_logo_main" alt="logo" /></Link>
            <h2>404 Page Not Found</h2>
            <h4>Looks like the page you are looking for doesn't exist!</h4>
        </div>
    )
}
