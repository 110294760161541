import React, { useState, useEffect } from "react";
import { postLead } from "../utils/utils";
import { string, object } from "yup";
import Button from "./Button";

export default function ContactForm({ inModal = false, setOpen }) {
  // Capitalization used for Zoho API
  const initialState = {
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone: "",
    Company: "",
    Description: "",
  }

  const [form, setForm] = useState({ ...initialState });
  const [errors, setErrors] = useState(null);
  const [formattedNumber, setFormattedNumber] = useState("");
  const [prevNumber, setPrevNumber] = useState("");
  const [successFormSubmission, setSuccessFormSubmission] = useState(null);
  const [formSubmissionError, setFormSubmissonError] = useState(null);

  useEffect(() => {
    phoneFormatter(formattedNumber)
  }, [formattedNumber]);

  const unformatPhoneNumber = (formattedNumber) => {
    const number = [];

    formattedNumber.split("").map((element) => {
      if (!isNaN(element)) {
        number.push(element);
      }
    });

    return number.join("");
  }

  const leadSchema = object({
    First_Name: string().required("First Name is required"),
    Last_Name: string().required("Last Name is required"),
    Email: string().email().required(),
    Phone: string().test({
      name: "is-phone",
      skipAbsent: true,
      test(value, ctx) {
        value = unformatPhoneNumber(value);

        if (!value) {
          return true;
        }

        if (value.length !== 10 || !Number(value)) {
          return ctx.createError({ message: "Phone number is invalid" });
        }
        return true;
      }
    }),
    Company: string().required(),
    Description: string().required("Please give some details of what you're looking for"),
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    let tempFormError = false;
    await leadSchema.validate(form, { abortEarly: false })
      .catch(err => {
        tempFormError = true;
        const errs = err.inner.reduce((acc, val) => {
          return {
            ...acc,
            [val.path]:
            {
              path: val.path,
              message: val.message
            }
          }
        }, {});
        setErrors(errs);
      });
    if (!tempFormError && !successFormSubmission) {
      const tempFetchResponce = await postLead(form);
      if (tempFetchResponce.status === 200) {
        setForm({ ...initialState });
        setSuccessFormSubmission(true);
      } else {
        setFormSubmissonError(true);
      }
    }
  }

  const phoneFormatter = (input) => {
    const length = input.length, numArray = [];

    input.split("").map((element) => {
      if (!isNaN(element) && element != " ") {
        numArray.push(element);
      }
    });

    if (length <= 5) {
      if (prevNumber.length < length) {
        numArray.splice(0, 0, "(");
        numArray.push(")");
      }
      setFormattedNumber(numArray.join(""));
    } else if (length > 5 && length <= 8) {
      numArray.splice(0, 0, "(");
      numArray.splice(4, 0, ")");
      setFormattedNumber(numArray.join(""));
    } else if (length <= 13 && formattedNumber != numArray.join("")) {
      numArray.splice(0, 0, "(");
      numArray.splice(4, 0, ")");
      numArray.splice(8, 0, "-");
      setFormattedNumber(numArray.join(""));
    }

    if (formattedNumber === "()") setFormattedNumber("");

    setPrevNumber(formattedNumber);
    setForm({
      ...form,
      ["Phone"]: formattedNumber
    });
    setErrors(null);
  }

  const changeHandler = (e) => {
    const inputName = e.target.name, inputValue = e.target.value;

    setForm({
      ...form,
      [inputName]: inputValue
    });

    inputName === "Phone" ? phoneFormatter(inputValue) : setErrors(null);
  }

  const cancelForm = () => {
    setOpen(false);
  }

  const formErrorClassHandler = (fieldType) => {
    return errors?.[fieldType] ? "error" : "";
  }

  const handleFieldErrorMessage = (fieldType) => {
    return <div className="error-message">{errors[fieldType].message}</div>
  }

  const submitButtonStyling = () => {
    if (successFormSubmission) {
      return { style: "success", message: "Thanks! We'll be in touch" }
    }
    else if (formSubmissionError) {
      return { style: "failed", message: "Whoops! We broke it..." }
    } else {
      return { style: "", message: "Submit Message" }
    }
  }

  return (
    <div className="contact-form container">
      {inModal && <h3 className="p-lg-5">Contact Us</h3>}
      <form onSubmit={submitHandler}>
        <div className="row g-3">
          <div className="col-lg-6">
            <input
              className={formErrorClassHandler("First_Name")}
              value={form.First_Name}
              name="First_Name"
              placeholder="First Name"
              onChange={changeHandler}
            />
            {errors?.First_Name && handleFieldErrorMessage("First_Name")}
          </div>
          <div className="col-lg-6">
            <input
              className={formErrorClassHandler("Last_Name")}
              value={form.Last_Name}
              name="Last_Name"
              placeholder="Last Name"
              onChange={changeHandler}
            />
            {errors?.Last_Name && handleFieldErrorMessage("First_Name")}
          </div>
          <div className="col-lg-6">
            <input
              className={formErrorClassHandler("Email")}
              value={form.Email}
              name="Email"
              placeholder="Email Address"
              onChange={changeHandler}
            />
            {errors?.Email && handleFieldErrorMessage("Email")}
          </div>
          <div className="col-lg-6">
            <input
              className={form.Phone && formErrorClassHandler("Phone")}
              value={form.Phone}
              name="Phone"
              placeholder="Phone Number (Optional)"
              onChange={changeHandler}
            />
            {(errors?.Phone && form.Phone) && handleFieldErrorMessage("Phone")}
          </div>
          <div className="col-12">
            <input
              className={formErrorClassHandler("Company")}
              value={form.Company}
              name="Company"
              placeholder="Company Name"
              onChange={changeHandler}
            />
            {errors?.Company && handleFieldErrorMessage("Company")}
          </div>
          <div className="col-12">
            <textarea
              className={formErrorClassHandler("Description")}
              value={form.Description}
              name="Description"
              placeholder="How can we help you?"
              onChange={changeHandler}
              rows={7}
            />
            {errors?.Description && handleFieldErrorMessage("Description")}

          </div>
          <div className={inModal ? "col-md-4" : "col-md-7"} />
          {inModal && <div className="col-lg-3"><Button text="Cancel" style="td-button neutral-3" callBack={cancelForm} /></div>}
          <div className="col-lg-5">
            <Button className={submitButtonStyling().style} text={submitButtonStyling().message} style="td-button td-button-1" type="submit" />
          </div>
        </div>
      </form>
    </div>
  )
}