import React from 'react';
import Chip from './Chip';
import { ReactComponent as LinkedIn } from "../images/icons/linkedin.svg";

export default function Headshot({ name, fullName, title, linkedin }) {
  /**
  * @desc Returns all webp paths in the images directory.
  * @param {function} r webpackContext(req) will return all ending with .webp in headshots directory. 
  * @returns {object} webp paths in headshots directory.
  */
  function importAll(r) {
    let imgs = {};
    r.keys().map(item => imgs[item.replace('./', '')] = r(item));
    return imgs;
  }

  const imgs = importAll(require.context('../images/headshots', false, /\.webp/));

  return (
    <div className="headshot d-flex justify-content-center">
      <a rel="noreferrer" target="_blank" href={linkedin}>
        <div className='headshot-img'>
          <img src={imgs[`${name}_headshot.webp`]} alt={`${name}'s headshot`}></img>
          <div className='my-3 mx-3 d-flex name'>
            <Chip text={fullName} style={1} />
            <LinkedIn />
          </div>
          <p className='body-3 mx-3'>{title}</p>
        </div>
      </a>
    </div>
  )
}
