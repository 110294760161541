import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { ReactComponent as BackArrow } from "../images/icons/arrow_back.svg";
import titleToURL from "../utils/utils";
import { getBlogs } from "../utils/utils";
import Svg from './Svg';


export default function Blog() {
  const [content, setContent] = useState();
  const { blogTitle } = useParams();

  const fetchBlog = async () => {
    const blogRes = await getBlogs(50);
    await blogRes.items.map((blogData) => {
      if (titleToURL(blogData.title) === blogTitle) {
        setContent(buildBlog(blogData));
      }
    });
  }

  useEffect(() => {
    if (!content) {
      setTimeout(() => {
        fetchBlog();
      }, "1000");
    }
  });

  const buildBlog = (blogContent) => {
    document.title = blogContent.title;
    return (
      <div className="blog-container" key={blogContent.id}>
        <a href="/blog" className="blog-back">
          <BackArrow />
          Back
        </a>
        <div className="blog-title">{blogContent.title}</div>
        <div className="blog-content">{parse(blogContent.content)}</div>
      </div>
    )
  }

  return (
    <div className='blog'>
      {content ? content : <div className='loading'><Svg name="tandev_logo_main" alt="logo" /></div>}
    </div>
  )
}
