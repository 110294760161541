import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { getBlogs } from "../utils/utils";
import { nextPageBlogs } from "../utils/utils";
import titleToURL from "../utils/utils";
import brynne from "../images/headshots/brynne_headshot.webp";
import ben from "../images/headshots/ben_headshot.webp";
import logo from "../images/tandev_logo_small.svg";
import blogData from "../utils/blogData.json";
import Banner from "./Banner";

export default function BlogLanding() {
  const [blogs, setBlogs] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();
  const [moreButton, setMoreButton] = useState(true);

  /**
* @desc Returns all png paths in the images directory.
* @param {function} r webpackContext(req) will return all ending with .png||jpg in images/blog directory. 
* @returns {object} png paths in headshots directory.
*/
  function importAll(r) {
    let imgs = {};
    r.keys().map(item => imgs[item.replace('./', '')] = r(item));
    return imgs;
  }

  const imgs = importAll(require.context('../images/blog', false, /\.(png|jpg)$/));

  const getNextPageToken = (data) => {
    setNextPageToken(data.nextPageToken);
  }

  const fetchBlogData = async () => {
    const blogRes = await getBlogs();
    getNextPageToken(blogRes);
    buildBlogs(blogRes);
  }

  const fetchNextPage = async (token) => {
    const blogRes = await nextPageBlogs(token);
    blogRes.nextPageToken ? getNextPageToken(blogRes) : setMoreButton(false);
    buildBlogs(blogRes);
  }

  useEffect(() => {
    document.title = 'TanDev - Blog';
    fetchBlogData();
  }, []);

  const buildBlogs = (blogRes) => {
    const blogDataArray = [];
    const arr = blogs;
    let name;

    Object.keys(blogData).forEach(key => blogDataArray.push({ title: blogData[key].title }));

    blogRes.items.map((blog) => {
      let blogIndex;

      blogDataArray.map((blogItem, index) => {
        if (blogItem.title === blog.title)
          blogIndex = index;
      });
      if (!isNaN(blogIndex)) {
        if (blogData[blogIndex].author == "Brynne Rivet") {
          name = brynne;
        } else if (blogData[blogIndex].author == "Ben Witzke") {
          name = ben;
        } else {
          name = logo;
        };
        if (blogs.length < 0) {
          arr.unshift(
            <Link to={`/blog/${titleToURL(blog.title)}`} key={blog.id}>
              <div className='blog-item' >
                <div className="blog-info">
                  <img src={imgs[blogData[blogIndex].image]} />
                  <div className="category">{blogData[blogIndex].category}</div>
                  <div className='title'>{blog.title}</div>
                </div>
                <div className="author-info">
                  <img src={name} />
                  <div className="author">{blogData[blogIndex].author}</div>
                  <div className="time">{blogData[blogIndex].time} min read</div>
                </div>
              </div>
            </Link>
          )
        } else {
          arr.push(
            <Link to={`/blog/${titleToURL(blog.title)}`} key={blog.id}>
              <div className='blog-item' >
                <div className="blog-info">
                  <img src={imgs[blogData[blogIndex].image]} />
                  <div className="category">{blogData[blogIndex].category}</div>
                  <div className='title'>{blog.title}</div>
                </div>
                <div className="author-info">
                  <img src={name} />
                  <div className="author">{blogData[blogIndex].author}</div>
                  <div className="time">{blogData[blogIndex].time} min read</div>
                </div>
              </div>
            </Link>
          );
        };
      } else {
        setMoreButton(false);
      };
    });
    setBlogs([...arr])
  };

  return (
    <div className='blog-landing'>
      <Banner title="TanDev Blog" subtitle="Keep up with latest company and software updates on our blog." />
      <div className='container'>
        <div className='blog-container'>
          <div className="blog-blogs">
            {blogs}
          </div>
          <div className="blog-button">
            {moreButton ? <div className="blog-more td-button td-button-1" onClick={() => fetchNextPage(nextPageToken)}>More</div> : null}
          </div>
        </div>
      </div>
    </div>
  )
}
