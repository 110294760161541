import React from "react";
import { Link } from "react-router-dom";
import Svg from "./Svg";
import eVerify from "../images/e_verify_logo.webp";
import { ReactComponent as Youtube } from "../images/icons/youtube.svg";
import { ReactComponent as LinkedIn } from "../images/icons/linkedin.svg";

export default function Footer() {

  return (
    <footer className="p-5">
      <div className="container">
        <div className="row g-3">
          <div className="col-lg-4">
            <div className="tandev-container">
              <Svg name="tandev_logo" alt=" tandev logo" />
              <p className="mb-3 mt-3">
                Bringing a collaborative mindset for web development solutions to companies across the United States.
              </p>
            </div>
            <div className="everify-container">
              <img className='everify-image' src={eVerify} alt="E-Verify" />
              <p className="mb-3 mt-1">
                TanDev is proud to be an E-Verified Company.
              </p>
            </div>
          </div>
          <div className="col-lg-2" /> {/* Boostrap spacer */}
          <div className="col-lg-2 col-sm-6 mb-4">
            <div className="row g-4 footer-col">
              <h4>Company</h4>
              <Link to={"/about"}>Our Story</Link>
              <Link to={"/services"}>Services</Link>
              <Link to={"/careers"}>Careers</Link>
              <div className="socials">
                <a href="https://www.youtube.com/@TanDevLLC" target="_blank" aria-label="YouTube">
                  <Youtube />
                </a>
                <a href="https://www.linkedin.com/company/tandev-llc/" target="_blank" aria-label="LinkedIn">
                  <LinkedIn />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-4">
            <div className="row g-4">
              <h4>Support</h4>
              <Link to={"/faq"}>FAQ</Link>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 mb-4">
            <div className="row g-4">
              <h4>Contact Us</h4>
              <span>
                <a href="mailto:support@tandev.net" target="_blank">support@tandev.net</a>
              </span>
            </div>
          </div>
          <div className="col-12 mt-3">
            <p className="copyright">
              © Copyright 2023. All Rights Reserved by TanDev
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
