import React, { useContext } from 'react';
import { ModalContext } from './ModalContext';

export default function List({ tier, list, hover }) {
  const { setOpen } = useContext(ModalContext);

  return (
    <div className={`tier-${tier} list`} onClick={() => { setOpen(true) }}>
      <p className='heading'>What you will get:</p>
      {list.map((i, index) => {
        return (
          <div className="text" key={index}>
            <p>{i}</p>
          </div>
        );
      })}
    </div>
  )
}
