import React from 'react';
import Svg from './Svg';

export default function Tool({ svg, title, experience, desc }) {
  return (
    <div className='tool'>
      <Svg name={svg.name} width={svg.width} height={svg.height} margin={svg.margin} />
      <h4>{title}</h4>
      <h6>{experience}</h6>
      <p className='body-2'>{desc}</p>
    </div>
  )
}
