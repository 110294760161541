import React from 'react';

export default function Banner({ title, subtitle }) {
  return (
    <div className='banner'>
      <div className='banner-overlay'>
        <div className='banner-container'>
          <h1>{title}</h1>
          <p className='subtitle'>{subtitle}</p>
        </div>
      </div>
    </div>
  )
}