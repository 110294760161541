import React, { useState } from "react";
import Svg from "./Svg";

export default function FAQ() {
  const initialContent = [
    {
      title: "Where do I start?",
      body: "Reach out to us through our chatbot or shoot us an email directly. We will try to get back to you as soon as we can to answer any additional questions and get the process started!",
      opened: false
    },
    {
      title: "What are your rates?",
      body: "Our rate is negotiable and largely depends on project scale, length, and time commitment. For a single developer our rate will start around $35/hr and for a vendor relationship our rate starts around $75/hr.",
      opened: false
    },
    {
      title: "Are there certain stages of a project you focus on?",
      body: "We are willing to step in at any step in your project. Whether this be planning and implementation or cleaning up old code, we're confident in our ability to help you out.",
      opened: false
    }
  ]

  const [content, setContent] = useState([...initialContent]);

  const clickHandler = (itemIndex) => {
    content.map((i, index) => {
      itemIndex == index ? setContent([...content], content[index].opened = !content[index].opened) : setContent([...content], content[index].opened = false);
    });
  }

  const questionContainer = content.map((item, index) => {
    return (
      <div className="question-container" key={index}>
        <div className="question-icon d-flex justify-content-between" onClick={() => clickHandler(index)}>
          <p className={`title${content[index].opened ? "-open" : "-close"}`}>{item.title}</p>
          <div className={`carrot${content[index].opened ? "-open" : "-close"}`}>
            <Svg name="carrot" height="15px" />
          </div>
        </div>
        <div className={content[index].opened ? "open" : "closed"}>{item.body}</div>
      </div>
    )
  })

  return (
    <div className="faq">
      <div className="container">
        <h2 className="text-center">FAQ</h2>
        <div className="row flex-row-reverse py-5 align-items-center">
          <div className="col-lg-5 col-12 mb-5">
            <Svg name="faq" width="100%" />
          </div>
          <div className="col-lg-1" />
          <div className="col-lg-6 col-12">
            {questionContainer}
          </div>
        </div>
      </div>
    </div>
  )
}