import React, { useEffect } from 'react';
import Headshot from "./Headshot";
import Svg from "./Svg";
import OurTeam from "./OurTeam";
import Video from "./Video";
import videos from "../utils/videos.json"
import Banner from "./Banner"

export default function About() {
  useEffect(() => {
    document.title = 'TanDev - About Us';
  }, []);

  return (
    <div className="about">
      <Banner title="About Us" subtitle="Bringing a collaborative mindset for web development solutions to companies across the United States." />
      <div className="about-video">
        <h2>Curious about it's like to work at TanDev?<br />Find out more about our Culture here.</h2>
        <Video id="825175122" width="1050px" />
      </div>
      <div className="about-mission">
        <h2>Our Mission</h2>
        <p>At TanDev, we prioritize people and companies first. We understand that businesses have needs that might fall outside of their necessary realm or scope and don’t have the time or energy for it to be done quickly and succinctly. TanDev steps in as that bridge. With a dedicated and multi-faceted U.S.-based team, both in development and business acumen, we are here to listen and put into practice your ideas, bringing them to life. Striving for customer satisfaction, we aim to use our experience and ability to deliver your project on time and go above and beyond to ensure that our customers have received their dreams, realized.</p>
      </div>
      <div className="about-team container">
        <h2>Our Team</h2>
        <div className="watermark">
          <Svg name="logomark" />
        </div>
        <div className="container team-container">
          <div className="row g-5">
            <Headshot name="michael" fullName="Michael Robbe" title="Founder / Senior Drupal Architect" linkedin="https://www.linkedin.com/in/michaelrobbe/" />
            <Headshot name="ben" fullName="Ben Witzke" title="Co-Founder / Business Operations" linkedin="https://www.linkedin.com/in/benjaminwitzke/" />
            <Headshot name="brynne" fullName="Brynne Rivet" title="Project Manager" linkedin="https://www.linkedin.com/in/brynne-rivet/" />
            <Headshot name="bargav" fullName="Bargav Kondapu" title="Senior Drupal Developer" linkedin="https://www.linkedin.com/in/bhar1red/" />
            <Headshot name="max" fullName="Maxwell Bos" title="Developer" linkedin="https://www.linkedin.com/in/maxwell-bos-162a9112b/" />
            <Headshot name="andrew" fullName="Andrew Wilson" title="Developer" linkedin="https://www.linkedin.com/in/andrewwilsonjs/" />
          </div>
        </div>
      </div>
      <div className="container my-5">
        <hr />
      </div>
      <OurTeam btnStyle={"4"} />
    </div>
  )
}