import React, { useEffect } from 'react';
import ServiceBlocks from './ServiceBlocks';
import Graph from './Graph';
import Specialties from "./Specialties";
import Banner from "./Banner"

export default function Services() {
  useEffect(() => {
    document.title = 'TanDev - Services';
  }, []);

  const graphData = [
    {
      price: "30",
      list: ["Jr. Developer", "Sr. Oversight"]
    },
    {
      price: "60",
      list: ["2 Jr. Developers", "Sr. Oversight"]
    },
    {
      price: "90",
      list: ["Jr. Developers", "Sr. Developer"]
    },
    {
      price: "110",
      list: ["Full Team of 5+ members"]
    }
  ];

  return (
    <div className='services'>
      <Banner title="Services" subtitle="Partner with TanDev to see your software goals come to fruition with a truly unique, cost-effective model built for both individuals, and businesses alike." />
      <ServiceBlocks highlights={true} />
      <Specialties />
      <div className='pricing'>
        <h2>Pricing Options</h2>
        <Graph data={graphData} />
      </div>
    </div>
  )
}
