import React from 'react';

export default function Svg({ name, width, height, margin, alt = "icon" }) {
    /**
     * @desc Returns all svg paths in the images directory.
     * @param {function} r webpackContext(req) will return all ending with .svg in images folder. 
     * @returns {object} Svg paths in images directory.
     */
    function importAll(r) {
        let svgs = {};
        r.keys().map(item => { svgs[item.replace('./', '')] = r(item); });
        return svgs;
    }

    const svgs = importAll(require.context('../images', false, /\.svg/));

    const svgStyles = {
        width: `${width}`,
        height: `${height}`,
        margin: `${margin}`
    };
    return <img alt={alt} style={svgStyles} src={svgs[`${name}.svg`]}></img>;
}
