import React from "react";
import Svg from "./Svg";
import Button from "./Button";

export default function OurTeam({ btnStyle = "1" }) {
  return (
    <div className='our-team'>
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-8 image-container">
            <Svg name="teamwork" margin="0" />
          </div>
          <div className='text col-lg-4 text-center text-lg-start my-auto'>
            <h3>Join Our Team</h3>
            <p className='body-2'>Make your next career move here. We have a diverse team that has come together with varying personal and professional backgrounds.</p>
            <Button className={btnStyle === "4" ? "text-lg-start text-center" : ""} width="200px" text="Learn more" to="/careers" style={btnStyle} />
          </div>
        </div>
      </div>
    </div>
  )
}