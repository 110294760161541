import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from './Button';
import Svg from './Svg';
import { Collapse } from "bootstrap";
import { ModalContext } from './ModalContext';

export default function Navbar() {
  const [toggle, setToggle] = useState(false);

  const { open, setOpen } = useContext(ModalContext);

  const location = useLocation();

  useEffect(() => {
    const myCollapse = document.getElementById('navbarNav');
    const bsCollapse = new Collapse(myCollapse, { toggle: false });
    toggle ? bsCollapse.show() : bsCollapse.hide();
  }, [toggle]);

  // Adding variables to change navbar color based on location
  const isBlogPage = location.pathname.startsWith("/blog/");
  const isFaqPage = location.pathname.startsWith("/faq");

  const toggleHandler = () => {
    // Only toggles when the mobile toggle is visible
    if (window.innerWidth <= 992) {
      setToggle(toggle => !toggle);

      if (toggle && window.scrollY <= 40 && !isBlogPage && !isFaqPage) {
        document.getElementById("navbar").className = "navbar navbar-expand-lg fixed-top";
      }

      if (!toggle) {
        document.getElementById("navbar").className = "navbar navbar-expand-lg active fixed-top";
      }
    }
  };

  function handleLogoClick(event) {
    if (location.pathname === '/') {
      // Prevent the default navigation since we're handling it
      event.preventDefault();
      // Scroll to the top
      window.scrollTo(0, 0);
      toggleHandler();
    }
  }

  const changeBackground = () => {
    if (window.scrollY <= 40 && !toggle && !isBlogPage && !isFaqPage) {
      document.getElementById("navbar").className = "navbar navbar-expand-lg fixed-top";
    } else {
      document.getElementById("navbar").className = "navbar navbar-expand-lg active fixed-top";
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <nav id="navbar" className={`navBar ${isBlogPage ? 'navbar navbar-expand-lg fixed-top active' : 'navbar navbar-expand-lg fixed-top'} ${isFaqPage ? 'navbar navbar-expand-lg active fixed-top' : 'navbar navbar-expand-lg fixed-top'}`}>
      <div className="container">
        <Link className='text' to='/' onClick={() => { handleLogoClick(); toggleHandler(); }}><Svg name="tandev_logo" alt="logo" /></Link>
        <button onClick={toggleHandler} className={toggle ? "navbar-toggler d-flex d-lg-none flex-column justify-content-around" : "navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"} type="button" >
          <span className="toggler-icon top-bar"></span>
          <span className="toggler-icon middle-bar"></span>
          <span className="toggler-icon bottom-bar"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav align-items-center ms-auto">
            <li>
              <Link onClick={toggleHandler} className={location.pathname === "/about" ? 'text nav-item current' : "text nav-item"} to='/about' >
                About us
              </Link>
            </li>
            {/* <li>
              <Link onClick={toggleHandler} className={location.pathname === "/about" ? 'text nav-item current' : "text nav-item"} to='/about' >
                Cases
              </Link>
            </li> */}
            <li>
              <Link onClick={toggleHandler} className={location.pathname === "/services" ? 'text nav-item current' : "text nav-item"} to='/services' >
                Services
              </Link>
            </li>
            {/* <li>
              <Link onClick={toggleHandler} className={location.pathname === "/services" ? 'text nav-item current' : "text nav-item"} to='/services' >
                Solutions
              </Link>
            </li> */}
            {/* <li>
              <Link onClick={toggleHandler} className={location.pathname === "/services" ? 'text nav-item current' : "text nav-item"} to='/services' >
                Clients
              </Link>
            </li> */}
            <li>
              <Link onClick={toggleHandler} className={location.pathname === "/blog" ? 'text nav-item current' : "text nav-item"} to='/blog' >
                Blog
              </Link>
            </li>
            <li>
              <Link onClick={toggleHandler} className={location.pathname === "/careers" ? 'text nav-item current' : "text nav-item"} to='/careers' >
                Contact us
              </Link>
            </li>
          </ul>
          <div className='ms-auto'><Button callBack={() => { setOpen(true); toggleHandler() }} text="Let's chat" style='5' width='fit-content'/></div>
        </div>
      </div>
    </nav>
  )
}