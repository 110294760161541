import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Homepage from './components/Homepage';
import About from './components/About';
import Services from './components/Services';
import Modal from './components/Modal';
import Careers from './components/Careers';
import ScrollToTop from './components/ScrollToTop';
import TriggerModal from './components/TriggerModal';
import FAQ from './components/FAQ';
import Blog from "./components/Blog";
import BlogLanding from './components/BlogLanding';
import PageNotFound from "./components/PageNotFound";
import { ModalContext } from './components/ModalContext';

export default function App() {
  const [open, setOpen] = useState(false);

  return (
    <ModalContext.Provider value={{ open, setOpen }}>
      {open && <Modal />}
      <Layout>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='about' element={<About />} />
          <Route path='services' element={<Services />} />
          <Route path='careers' element={<Careers />} />
          <Route path='contact' element={<> <TriggerModal /><Homepage /> </>} />
          <Route path="faq" element={<FAQ />} />
          <Route path="blog" element={<BlogLanding />} />
          <Route path="blog/:blogTitle" element={<Blog />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Layout>
    </ModalContext.Provider>
  )
}
