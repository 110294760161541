import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BackArrow } from "../images/icons/arrow_back.svg";

export default function Button({ className = "", text, to, callBack = null, style, disabled, width = "100%", type = null, arrow = false }) {

  const button = callBack || type ?
    <button className={`td-button td-button-${style} ${disabled ? `disabled` : ``} ${className}`} style={{ width: width }} onClick={callBack} type={type} >
      {text} {arrow || style == 6 ? <BackArrow className="arrow" />: null}
    </button>
    :
    <Link className={`td-button td-button-${style} ${disabled ? `disabled` : ``} ${className}`} style={{ width: width }} to={to}>
      <h6>{text}</h6>
    </Link>

  return button;
}
