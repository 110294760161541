import axios from "axios";

export async function postLead(lead) {
  const api = process.env.NODE_ENV === "development" ? "http://localhost:3001/contact" : "https://tandev-site-api.herokuapp.com/contact";
  const client = process.env.NODE_ENV === "development" ? "http://localhost:3000" : "www.tandev.net";

  return await axios({
    url: api,
    method: 'post',
    data: JSON.stringify(lead),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": client
    },
  }).then(res => res).catch(err => { console.error(err); return err });
}

export async function getBlogs(maxResults = 9) {
  return await axios({
    url: `https://www.googleapis.com/blogger/v3/blogs/4013029788430326457/posts`,
    method: 'get',
    params: {
    //   key:  `${process.env.REACT_APP_BLOG_API_KEY}`,
      key:  `AIzaSyAWYZvOfOaSK_I5nZNldyYuxGA1ZdOKbXg`,
      maxResults: `${maxResults}`,
    },
  }).then(res => res.data).catch(err => { console.error(err); return err });
}

export async function nextPageBlogs(nextPageToken, maxResults = 9) {
  return await axios({
    url: `https://www.googleapis.com/blogger/v3/blogs/4013029788430326457/posts`,
    method: 'get',
    params: {
      key:  `${process.env.REACT_APP_BLOG_API_KEY}`,
      maxResults: `${maxResults}`,
      pageToken: `${nextPageToken}`
    },
  }).then(res => res.data).catch(err => { console.error(err); return err });
}

export default function titleToURL(title) {
  return title.trim().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase();
}