import React, { useState, useEffect } from 'react';
import Tool from './Tool';

export default function Specialties() {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const scrollContainer = document.getElementsByClassName("scroll-x")[0];
        const onScroll = () => {
            setScroll(scrollContainer.scrollLeft);
        }

        scrollContainer.addEventListener("scroll", onScroll);
        return () => scrollContainer.removeEventListener("scroll", onScroll);
    }, [scroll]);

    return (
        <div className="specialties">
            <div className="title">
                <h2>Our Tech Stack</h2>
                <p className="body-2 text-center">We have multiple years of experience in the following programs, all gained while working on diverse projects with leading companies in the tech industry.</p>
            </div>
            <div className="container">
                <div className="about-tools row">
                    <div className="scroll-x">
                        <Tool svg={{ name: "drupal", width: "83px", height: "108px" }} title="Drupal" experience="8 years" desc="Worked on multiple large site projects using Drupal 7-9." />
                        <Tool svg={{ name: "javascript_icon", width: "83px", height: "108px" }} title="JavaScript" experience="13 years" desc="Exposure to Typescript, React, VueJS, EmberJS, and NodeJS." />
                        <Tool svg={{ name: "php_icon", width: "83px", height: "108px" }} title="PHP" experience="13 years" desc="Used while working with multiple CMS's including: WordPress, SilverStripe, Joomla, and Symphony." />
                        <Tool svg={{ name: "html_icon", width: "83px", height: "108px" }} title="HTML 5" experience="15 years" desc="Utilized in multiple professional projects." />
                        <Tool svg={{ name: "css_icon", width: "83px", height: "108px" }} title="CSS 3" experience="15 years" desc="Experienced using preprocesses such as SASS/SCSS, Stylus, and Less." />
                        <Tool svg={{ name: "sql_icon", width: "83px", height: "108px" }} title="MySQL" experience="12 years" desc="Utilized in multiple professional projects." />
                        <Tool svg={{ name: "devops_icon", width: "83px", height: "108px" }} title="DevOps" experience="Varying" desc="AWS (6 years), Apache (12 years), Docker (4 years)." />
                    </div>
                </div>
                <div className="carousel-points">
                    <div className="border" onClick={() => document.getElementsByClassName("scroll-x")[0].scrollLeft = 0}>
                        <div className={scroll <= 550 ? "active" : ""}></div>
                    </div>
                    <div className="border" onClick={() => document.getElementsByClassName("scroll-x")[0].scrollLeft = 940}>
                        <div className={scroll > 550 ? "active" : ""}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
