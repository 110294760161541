import React, { useEffect } from 'react';
import Diamond from './Diamond';
import Svg from './Svg';
import Banner from "./Banner"

export default function Careers() {
  useEffect(() => {
    document.title = 'TanDev - Careers';
  }, []);

  return (
    <div className='careers'>
      <Banner title="Careers" subtitle="Joining our team gives you opportunities and advantages to grow in your software career while continuing to improve your skillset." />
      <div className="container">
        <div className='row flex-row-reverse content'>
          <div className='col-lg-5 col-12'>
            <Svg name="tech_worker_man" />
          </div>
          <div className='col-lg-7 col-12 text'>
            <h3 className="text-center text-lg-start">Make your next career move here</h3>
            <div className='section'>
              <div className='heading'>
                <Diamond hover={true} />
                <h5>First Class Pay</h5>
              </div>
              <p className='body-2'>Pay is on par with market rates with the ability to scale up with more projects.</p>
            </div>
            <div className='section'>
              <div className='heading'>
                <Diamond hover={true} />
                <h5>Growth Potential</h5>
              </div>
              <p className='body-2'>You have the ability to take on as many projects as you feel capable of handling, gaining experience and diversifying your strengths.</p>
            </div>
            <div className='section'>
              <div className='heading'>
                <Diamond hover={true} />
                <h5>Flexible Location</h5>
              </div>
              <p className='body-2'>While being on location is preferred, opportunity for a position has the ability to be fully remote.</p>
            </div>
            <h5 className='link'>Check out our <a href="https://www.linkedin.com/company/tandev-llc/jobs/" target="_blank">LinkedIn</a> page for any current available roles</h5>
          </div>
        </div>
        <div className='footer'>
          <h5>Tools we work with</h5>
          <div className="row g-5 g-lg-0">
            <div className="col-lg-2 col-4">
              <Svg name="php_icon" width="102px" height="64px" />
            </div>
            <div className="col-lg-2 col-4">
              <Svg name="html_icon" width="57px" height="64px" />
            </div>
            <div className="col-lg-2 col-4">
              <Svg name="css_icon" width="57px" height="64px" />
            </div>
            <div className="col-lg-2 col-4">
              <Svg name="react_icon" width="60px" height="64px" />
            </div>
            <div className="col-lg-2 col-4">
              <Svg name="javascript_icon" width="57px" height="64px" />
            </div>
            <div className="col-lg-2 col-4">
              <Svg name="drupal" width="49px" height="64px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
